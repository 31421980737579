import React from "react";
import { Helmet } from "react-helmet";
import Config from "../Config.json";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

const TITLE = "Home | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";
//const CANONICAL = Config.SITE_DOMAIN + "/";

class HomeEs extends React.Component {
  render() {
    return (
      <main className="main-content">
        <Helmet>
          <title>{TITLE}</title>
          <Link rel="canonical" href="{CANONICAL}"></Link>
          <meta name="description" content={DESC} />
          <meta name="theme-color" content={Config.THEME_COLOR} />
          <meta
            name="description"
            content="Descubre las últimas tendencias, herramientas e innovaciones en Inteligencia Artificial (IA). Nuestro blog cubre el uso de la IA en la vida cotidiana, desde tecnologías inteligentes hasta juegos impulsados por IA y consejos útiles para utilizarla eficazmente."
          />
        </Helmet>
        {/* Hero szekció */}
        <section className="hero  col-12 col-sm-12 col-md-12 col-lg-12 ">
          <h1>
            Descubra cómo la inteligencia artificial está dando forma a la vida
            cotidiana.
          </h1>
        </section>
        {/* Cookie Consent Modal */}
        <div id="cookieModal" className="cookie-modal">
          <div className="cookie-modal-content">
            <h3>El sitio utiliza cookies</h3>
            <p>
              Nuestro sitio web utiliza cookies para mejorar la experiencia de
              uso del usuario. Acepte las cookies para disfrutar de una
              experiencia de usuario completa con el fin de
            </p>
            <div className="cookie-buttons">
              <button
                id="acceptCookies"
                className="btn btn-AIinsideLife-primary"
              >
                I accept it
              </button>
              <button id="rejectCookies" className="btn btn-secondary">
                Me niego
              </button>
            </div>
          </div>
        </div>

        {/* Legfrissebb bejegyzések */}
        <section className="latest-posts ">
          <h2>
            <b>IA generativa dentro de la vida</b>
          </h2>
          <div className="container mt-5">
            <article className="post post-left row mb-4 py-3 shadow">
              <div className="col-3">
                <img
                  className="img-fluid mt-2 mb-2"
                  src="../images/flux-tiktok.png"
                  alt="Bejegyzés kép"
                />
              </div>
              <div className="col-8 mx-2 shadow-sm rounded">
                <h3>Tendencias de la IA en 2024</h3>
                <h3>Notas de TikTok, competidor de Instagram:</h3>
                <p>
                  Descubra las últimas tendencias y tecnologías de IA que le
                  ayudarán dominar en 2024. TikTok ha realizado un movimiento
                  audaz y anunció recientemente un nuevo competitivo con
                  Instagram el Próxima introducción de la aplicación para
                  compartir fotos. Los poderosos usuarios únicos de IA de TikTok
                  que utilizan algoritmos quieren proporcionar una experiencia,
                  desafiando así a Instagram a un on el segmento de intercambio
                  de fotografías de las redes sociales. Según el noticias, esta
                  aplicación utilizará funciones innovadoras y la aplicación
                  ofrecer una nueva perspectiva sobre las interacciones en las
                  redes sociales. en un Declaración enviada a los usuarios,
                  TikTok la hizo pública en publicaciones de fotos. llamado
                  "Notas TikTok". planea lanzar un especialista plataforma.
                </p>
                <Link to="/Blogs/AITrendek">seguiré leyendo</Link>
              </div>
            </article>
            <article className="post post-right row mb-4 py-3 shadow">
              <div className="col-9">
                <h3>Aprendizaje automático simplificado</h3>
                <h3>
                  Aprenda todo sobre el aprendizaje automático de una manera
                  simple y manera comprensible.
                </h3>

                <p>
                  El aprendizaje automático es importante porque brinda a las
                  empresas una visión de las tendencias en el comportamiento del
                  cliente y el negocio operativo patrones, así como apoya el
                  desarrollo de nuevos productos. Muchas de las empresas líderes
                  de la actualidad, como Facebook, Google, y Uber, hacen del
                  aprendizaje automático una parte central de su operaciones.
                </p>
                <Link to="/Blogs/AITrendek">seguiré leyendo</Link>
              </div>
              <div className="col-3">
                <img
                  className="img-fluid mt-2 mb-2"
                  src="../images/machine-learning.webp"
                  alt="Bejegyzés kép"
                />
              </div>
            </article>
            <article className="post post-left row mb-3 py-3 shadow">
              <div className="col-3">
                <img
                  className="img-fluid mt-2 mb-2 "
                  src="../images/jasperai.png"
                  alt="Bejegyzés kép"
                />
              </div>
              <div className="col-9">
                <h3>Herramientas de IA para empresas</h3>
                <p>
                  Las mejores herramientas de IA para ayudar a aumentar la
                  eficiencia de su negocio.
                </p>
                <p>
                  Mucha gente conoce a Jasper como el mejor asistente de
                  escritura de IA que existe. Lidera el mercado con sus
                  impresionantes características y calidad. Primero debes
                  proporcionar las palabras iniciales que Jasper analiza, antes
                  de traer frases, párrafos o documentos creados en base sobre
                  el tema y el tono. Se pueden escribir menos de 1.500 minutos.
                  un artículo de 15 palabras a continuación.
                </p>
                <Link to="/Blogs/AITrendek">Seguiré leyendo</Link>
              </div>
            </article>
          </div>
        </section>
        {/* AI Eszközök */}
        <section className="tools container">
          <h2>
            <b>Herramientas de IA recomendadas</b>
          </h2>
          <div className="tools-list">
            <div className="tool-item-p">
              <img
                className="img-fluid2 mt-2 mb-2 "
                src="../images/Robovision-pink-logo-banner.jpg"
                alt="AI Eszköz Robovision-pink-logo-banner.jpg"
              />
              <h3>Plataforma de visión por computadora impulsada por IA.</h3>
              <p>
                Potente herramienta de inteligencia artificial para el
                reconocimiento de imágenes.
              </p>
              <p>
                Robovision ofrece una plataforma de visión AI que cubre toda la
                IA ciclo vital. Agilizar todo el proceso de desarrollo,
                Implementar y adaptar la IA en los negocios en constante cambio
                de hoy. ambiente. Creando aplicaciones para visión por
                computadora compleja Los desafíos nunca han sido tan fáciles
                gracias al poder de la IA y la plataforma Robovisión.
              </p>
              <Link to="/tools/tool-1">Descubra más</Link>
            </div>
            <div className="tool-item-u">
              <img
                className="img-fluid2 mt-2 mb-2 "
                src="../images/freshdesk-logo.svg"
                alt="AI Eszköz Freshdesk"
              />
              <h3>
                <b>Freshdesk</b>
              </h3>
              <p>Solución de servicio al cliente basada en IA.</p>
              <p>Ofrezca un servicio al cliente sin esfuerzo con Freshdesk</p>
              <Link to="/tools/tool-2">Descubra más</Link>
            </div>
            <div className="tool-item-p">
              <img
                className="img-fluid2 mt-2 mb-2 "
                src="../images/Bard-AI-Logo.png"
                alt="AI Eszköz Bard AI"
              />
              <h3>
                <b>Bard AI</b>
              </h3>
              <p>
                Una herramienta de IA que ayuda a aumentar la productividad.
              </p>
              <p>
                Bard es un servicio experimental de IA conversacional
                desarrollado por Google, impulsado por su modelo de lenguaje
                para aplicaciones de diálogo (LaMDA). Está diseñado para brindar
                respuestas frescas y de alta calidad. extrayendo información de
                la web. Atributos de bardo directos citas extensas a la página
                web de origen y ofrece a los usuarios la capacidad de ver y
                potencialmente hacer clic para acceder al original fuente a
                través de URL o miniaturas de imágenes para verificación y más
                exploración
              </p>
              <Link to="/tools/tool-3">Descubra más</Link>
            </div>
          </div>
        </section>
        {/* Hírlevél feliratkozás */}
        <section className="newsletter container">
          <h2>¡Suscríbete a nuestro boletín!</h2>
          <p>No se pierda las últimas tendencias e innovaciones en IA.</p>
          <form>
            <input type="email" placeholder="Email address" />
            <button type="submit">Suscripción</button>
          </form>
        </section>
      </main>
    );
  }
}

export default HomeEs;
