import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useParams,
} from "react-router-dom";
import HomeHu from "./pages/HomeHu";
import HomeEn from "./pages/HomeEn";
import HomeDe from "./pages/HomeDe";
import HomeEs from "./pages/HomeEs";
import HomeFr from "./pages/HomeFr";
import BlogHu from "./pages/BlogHu";
import BlogPost from "./Blogs/BlogPost";
import BlogDe from "./pages/BlogDe";
import BlogEn from "./pages/BlogEn";
import BlogEs from "./pages/BlogEs";
import BlogFr from "./pages/BlogFr";
import Article from "./Blogs/Article";
import AitoolsComponent from "./pages/Aitools";
import AitoolsHu from "./pages/AitoolsHu";
import AitoolsDe from "./pages/AitoolsDe";
import AitoolsFr from "./pages/AitoolsFr";
import AitoolsEs from "./pages/AitoolsEs";
import LearningEn from "./pages/LearningEn";
import LearningHu from "./pages/LearningHu";
import LearningDe from "./pages/LearningDe";
import Attendance from "./pages/Attendance";
import ContactHu from "./pages/ContactHu";
import ContactEn from "./pages/ContactEn";
import ContactDe from "./pages/ContactDe";
import ContactEs from "./pages/ContactEs";
import ContactFr from "./pages/ContactFr";
import PageNotFound from "./components/PageNotFound";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TranslatedHeader from "./components/Header"; // Import the TranslatedHeader
import Footer from "./components/Footer"; // Assuming you have a footer

import "bootstrap/dist/js/bootstrap.bundle.min"; // Bootstrap import
import "./App.css";

function App() {
  return (
    <Router>
      <TranslatedHeader />
      <Routes>
        <Route path="/" element={<Navigate to="/en/Home" replace={true} />} />
        <Route path="/:lng/Home" element={<Home />} />
        <Route path="/:lng/Blog" element={<Blog />} />
        <Route path="/:lng/Aitools" element={<Aitools />} />
        <Route path="/:lng/Learning" element={<Learning />} />
        <Route path="/:lng/Contact" element={<Contact />} />
        <Route path="/:lng/BlogPost" element={<BlogPost />} />
        <Route path="/Article" element={<Article />} />
        <Route path="/:lng/Attendance" element={<Attendance />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </Router>
  );
}

// Dinamikus komponensek a megfelelő oldalakhoz
function Home() {
  const { lng } = useParams();
  switch (lng) {
    case "hu":
      return <HomeHu />;
    case "en":
      return <HomeEn />;
    case "de":
      return <HomeDe />;
    case "es":
      return <HomeEs />;
    case "fr":
      return <HomeFr />;
    default:
      return <Navigate to="/en/Home" replace={true} />;
  }
}

// Blog dinamikus komponens
function Blog() {
  const { lng } = useParams();
  switch (lng) {
    case "hu":
      return <BlogHu />;
    case "en":
      return <BlogEn />;
    case "de":
      return <BlogDe />;
    case "es":
      return <BlogEs />;
    case "fr":
      return <BlogFr />;
    default:
      return <Navigate to="/en/Blog" replace={true} />;
  }
}

// Aitools dinamikus komponens
function Aitools() {
  const { lng } = useParams();
  switch (lng) {
    case "hu":
      return <AitoolsHu />;
    case "en":
      return <AitoolsComponent />;
    case "de":
      return <AitoolsDe />;
    case "es":
      return <AitoolsEs />;
    case "fr":
      return <AitoolsFr />;
    default:
      return <Navigate to="/en/Aitools" replace={true} />;
  }
}

// Learning dinamikus komponens
function Learning() {
  const { lng } = useParams();
  switch (lng) {
    case "hu":
      return <LearningHu />;
    case "en":
      return <LearningEn />;
    case "de":
      return <LearningDe />;
    default:
      return <Navigate to="/en/Learning" replace={true} />;
  }
}

// Contact dinamikus komponens
function Contact() {
  const { lng } = useParams();
  switch (lng) {
    case "hu":
      return <ContactHu />;
    case "en":
      return <ContactEn />;
    case "de":
      return <ContactDe />;
    case "es":
      return <ContactEs />;
    case "fr":
      return <ContactFr />;
    default:
      return <Navigate to="/en/Contact" replace={true} />;
  }
}

export default App;
