import React, { useState, useRef, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const languages = [
  { code: "hu", name: "Magyar", country_code: "hu" },
  { code: "en", name: "English", country_code: "gb" },
  { code: "de", name: "Deutsch", country_code: "de" },
  { code: "es", name: "Español", country_code: "es" },
  { code: "fr", name: "Français", country_code: "fr" },
];

const GlobeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    className="bi bi-globe"
    viewBox="0 0 16 16"
  >
    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 13.7 0 0 1-.312 2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z" />
  </svg>
);

const TranslatedHeader = () => {
  const { t, i18n } = useTranslation();
  const [navbarOpen, setNavbarOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const currentLang = i18n.language;

  // Nyelvváltás
  const [loading, setLoading] = useState(true);

  // Nyelv váltás
  const handleLanguageChange = (code) => {
    const currentPath = window.location.pathname;
    const regex = /^\/[a-z]{2}/;
    const newPath = currentPath.replace(regex, `/${code}`);

    setLoading(true); // Tölti állapot

    i18n
      .changeLanguage(code)
      .then(() => {
        navigate(newPath);
      })
      .catch((err) => {
        console.error("Error changing language:", err);
      })
      .finally(() => {
        setLoading(false); // Visszaállítja a tölti állapotot
      });
  };

  // Menüpont URL-ek generálása
  const createLocalizedLink = (path) => `/${currentLang}${path}`;

  // Hamburger menü bezárása menüpontra kattintáskor
  const handleNavLinkClick = () => {
    setNavbarOpen(false);
  };

  return (
    <header>
      <Helmet>
        <link rel="canonical" href="https://salgosoft.hu/en/Home" />
        {/* Dublin Core meta tag-ek */}
        <meta name="DC.title" content="Az oldal címe" />
        <meta name="DC.creator" content="Szerző neve" />
        <meta name="DC.subject" content="Téma / kulcsszavak" />
        <meta name="DC.description" content="Az oldal leírása" />
        <meta name="DC.publisher" content="Kibocsátó neve" />
        <meta name="DC.date" content="YYYY-MM-DD" />
        <meta name="DC.language" content="hu" />
        <meta name="DC.identifier" content="https://salgosoft.hu/en/Home" />
        <meta name="DC.rights" content="Jogok" />
        {/* Open Graph meta tag-ek */}
        <meta property="og:title" content="Az oldal címe" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://salgosoft.hu/en/Home" />
        <meta
          property="og:image"
          content="https://salgosoft.hu/path-to-image.jpg"
        />
        <meta
          property="og:description"
          content="Rövid leírás az oldal tartalmáról."
        />
        <meta property="og:site_name" content="Weboldal Neve" />
        <meta
          name="description"
          content="To use this application, you need to enable JavaScript in your browser settings. JavaScript is essential for the proper functioning of this app."
        ></meta>
      </Helmet>
      {/* <h1> Elem hozzáadása */}
      <h1 style={{ display: "none" }}>{t("Page_Title")}</h1>{" "}
      {/* Ezt adaptáld a megfelelő fordítással */}
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <NavLink className="navbar-brand" to={createLocalizedLink("/Home")}>
            <img src="../images/gptLogo.webp" alt="Logo" className="gtp" />
            {t("Brand_name")}
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${navbarOpen ? "show" : ""}`}
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={createLocalizedLink("/Home")}
                  onClick={handleNavLinkClick}
                >
                  {t("Site_1")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={createLocalizedLink("/Blog")}
                  onClick={handleNavLinkClick}
                >
                  {t("Site_2")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={createLocalizedLink("/Aitools")}
                  onClick={handleNavLinkClick}
                >
                  {t("Site_3")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={createLocalizedLink("/Learning")}
                  onClick={handleNavLinkClick}
                >
                  {t("Site_4")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={createLocalizedLink("/Contact")}
                  onClick={handleNavLinkClick}
                >
                  {t("Site_6")}
                </NavLink>
              </li>
            </ul>
            <div className="dropdown">
              <button
                className="btn btn-link dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <GlobeIcon />
              </button>
              <ul className="dropdown-menu">
                {languages.map(({ code, name, country_code }) => (
                  <li key={code}>
                    <button
                      className="dropdown-item "
                      onClick={() => handleLanguageChange(code)}
                    >
                      <span
                        className={`flag-icon flag-icon-${country_code} me-2`}
                      ></span>
                      {name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default TranslatedHeader;
